import React, { Component } from 'react';
import PublicPage from '../common/containers/PublicPage';
import { withNamespaces } from 'react-i18next';
import Content from '../common/containers/Content';
import Col from '../common/containers/Col';
import Row from '../common/containers/Row';

class AboutPage extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <PublicPage title={i18n.t('Sobre')}>
        <Content>
          <Row>
            <Col md={8} mdOffset={2}>
              <h2 className="text-primary">
                Com mais de 300 modelos e mais de 15 anos de experiência de seus
                criadores, o FastFormat é referência de qualidade no mercado
                acadêmico.
              </h2>
              <hr />

              <h2>O que é?</h2>
              <p>
                O FastFormat é um software online para editoração eletrônica
                baseada em modelos, cujo foco principal é a elaboração de
                trabalhos acadêmicos, mas não limitado a isso. A ferramenta foi
                criada para atender a um público crescente que precisa escrever
                textos acadêmicos, mas vê nas regras de formatação e softwares
                existentes um empecilho ao seu trabalho.
              </p>

              <p>
                <b>Modelos.</b> São os vários modelos de formatação de
                documentos acadêmicos existentes, como: artigo de conferência,
                artigo de periódico, trabalhos de conclusão de curso,
                monografias, dissertações de mestrado, teses de doutorado,
                dentre outros. O FastFormat faz uso de modelos para que seus
                usuários não precisem se preocupar com formatação de texto e
                referências bibliográficas. Nosso objetivo é que o usuário foque
                apenas no conteúdo, afinal é o que interessa.
              </p>

              <p>
                Além do benefício da formatação automática, o modelo dos
                documentos pode ser substituído a qualquer momento, com apenas
                alguns cliques e sem qualquer prejuízo ao conteúdo ou formatação
                do texto. Isso facilita o trabalho de pessoas que precisam
                submeter seu conteúdo para veículos de publicação diferentes.
              </p>

              <p>
                <b>Referências.</b> As referências e citações são,
                frequentemente, os pontos mais problemáticos da escrita
                acadêmica. Pensando nisso, o FastFormat integra uma solução
                completa para gerenciamento de bibliografia, a qual é facilmente
                utilizada no editor de documentos. Junto com o mecanismo de
                Modelos, as referências presentes em um documento são formatadas
                automaticamente de acordo com o modelo escolhido, de forma
                totalmente transparente.
              </p>

              <h2>A equipe</h2>
              <p>
                A equipe que desenvolve o FastFormat e fornece o suporte aos
                usuários é formada por pessoas experientes na área acadêmica.
                Nós já passamos por todas as dificuldades inerentes a produção
                de texto acadêmicos e científicos. Essa experiência, juntamente
                com nossa formação, permite-nos fornecer serviços exclusivos e
                de qualidade aos nossos usuários.
              </p>

              <h2>Missão</h2>
              <p>
                <i>
                  Nossa missão é eliminar qualquer complexidade tecnológica
                  referente a escrita acadêmica e científica, fazendo com que
                  nossos usuários desenvolvam todo seu potencial na produção de
                  novos conhecimentos.
                </i>
              </p>

              <hr />
              <h2>&copy; 2015-{new Date().getFullYear()} FastFormat</h2>
              <p>
                BPY Soluções Tecnológicas Ltda - ME
                <br />
                CNPJ 25.126.480/0001­62
                <br />
                Rua do Sossego, n 298, Caixa Postal 383
                <br />
                Boa Vista, Recife-PE
                <br />
                CEP 50050-­080
              </p>
            </Col>
          </Row>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(AboutPage);
